// Core
import { ref } from "vue";
import { v4 as uuidv4 } from "uuid";

// Libraries
import { projectStorage } from "../firebase/config";
import { ref as refStorage, uploadBytesResumable, getDownloadURL, deleteObject } from "firebase/storage";

// Services & Helpers
import { errorHandlerService } from "@/core/services/error";

// Composables
import { useUser } from "./useUser";

export const useStorage = (isUser: boolean = true) => {
  const { user } = useUser();

  const loading = ref(false);
  const uploadProgress = ref(0);
  const error = ref<unknown | null>(null);
  const filePath = ref<string | null>(null);
  const url = ref<string | null>(user.value?.photoURL && isUser ? user.value?.photoURL : null);

  const generateRandomId = (): string => {
    return uuidv4();
  };

  const uploadImage = async (file: File, type: string) => {
    if (!user.value) return;

    loading.value = true;
    const randomId = generateRandomId();
    filePath.value = `${type}/${user.value.uid}/${randomId}-${file.name}`;

    const storageRef = refStorage(projectStorage, filePath.value);

    try {
      const uploadTask = await uploadBytesResumable(storageRef, file);
      url.value = await getDownloadURL(uploadTask.ref);
    } catch (err) {
      errorHandlerService.handleError(err);
      error.value = err;
    } finally {
      loading.value = false;
    }
  };

  const uploadFile = async (file: File, type: "meetbase") => {
    if (!file || !user.value) return;

    loading.value = true;
    const randomId = generateRandomId();
    filePath.value = `uploads/${type}/${user.value.uid}/${randomId}-${file.name}`;

    const fileRef = refStorage(projectStorage, filePath.value);
    const uploadTask = uploadBytesResumable(fileRef, file);

    uploadTask.on(
      "state_changed",
      snapshot => {
        uploadProgress.value = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      },
      err => {
        errorHandlerService.handleError(err);

        error.value = err;
        loading.value = false;
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then(downloadURL => {
          url.value = downloadURL;
          loading.value = false;
        });
      }
    );
  };

  const deleteImage = async (path: string) => {
    const storageRef = refStorage(projectStorage, path);

    try {
      await deleteObject(storageRef);
      url.value = null;
    } catch (err) {
      error.value = err;
      errorHandlerService.handleError(err);
    }
  };

  const setInStorage = (key: string, value: string): void => {
    try {
      localStorage.setItem(key, value);
    } catch (error) {
      console.error("Erro ao salvar no localStorage:", error);
    }
  };

  const getStoredItem = (key: string): string | null => {
    try {
      return localStorage.getItem(key);
    } catch (error) {
      console.error("Erro ao acessar localStorage:", error);
      return null;
    }
  };

  return {
    uploadFile,
    uploadImage,
    deleteImage,
    setInStorage,
    getStoredItem,
    url,
    error,
    loading,
    filePath,
    uploadProgress
  };
};
