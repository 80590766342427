// Services & Helpers
import { defaultErrorMessages } from "@/core/services/error/defaultErrors";

// Composables
import { toast } from "@/composables/useCustomToast";

// Types
import type { ApiError, ApiErrorCode } from "@/@types/api";
import type { ErrorTable, ToastErrorMessage } from "@/core/services/error/types";

export class ErrorHandlerService {
  private errorTable: Partial<ErrorTable>;
  private localCustomErrorTable: Partial<ErrorTable> | undefined;

  constructor(errorTable = defaultErrorMessages, localCustomErrorTable?: Partial<ErrorTable>) {
    this.errorTable = errorTable;
    this.localCustomErrorTable = localCustomErrorTable;
  }

  handleError(
    error?: ApiErrorCode | unknown,
    customMessage?: Partial<ErrorTable>,
    param?: Record<string, string>,
    showToast?: boolean
  ): Partial<ToastErrorMessage>;

  handleError(
    error?: string | ApiError,
    customMessage?: Partial<ErrorTable>,
    param?: Record<string, string>,
    showToast?: boolean
  ): Partial<ToastErrorMessage>;

  handleError(
    error?: ApiErrorCode | ApiError,
    customMessage?: Partial<ErrorTable>,
    param?: Record<string, string>,
    showToast = true
  ) {
    let errorMessage: Partial<ToastErrorMessage>;
    let errorCode: string | undefined;

    if (error && typeof error === "object" && ("error" in error || "message" in error)) {
      errorCode = error.error || error.message || "default";
      errorMessage = this.getDefaultErrorMessage(errorCode, customMessage);
    } else {
      errorCode = (error as ApiErrorCode | string) || "default";
      errorMessage = this.getDefaultErrorMessage(errorCode, customMessage);
    }

    const errorMessageWithParams = errorMessage.message?.replaceAll(
      /\{\{(\w+)\}\}/g,
      (match, key) => param?.[key] || match
    );

    errorMessage.message = errorMessageWithParams || errorMessage.message;

    if (showToast) toast.error({ title: errorMessage.title, message: errorMessage.message });

    return errorMessage;
  }

  getDefaultErrorMessage(code: ApiErrorCode | string, custom: Partial<ErrorTable> = {}): Partial<ToastErrorMessage> {
    // Use 'this.localCustomErrorTable' se 'custom' não for passado
    const effectiveCustom = Object.keys(custom).length > 0 ? custom : this.localCustomErrorTable;

    const defaultErrorMessage =
      effectiveCustom?.default ||
      (code in this.errorTable ? this.errorTable[code as keyof ErrorTable] : this.errorTable.default!);
    const customErrorMessage =
      effectiveCustom && code in effectiveCustom ? effectiveCustom[code as keyof ErrorTable] : undefined;

    if (!effectiveCustom || !customErrorMessage) return defaultErrorMessage as ToastErrorMessage;

    if (customErrorMessage.message && customErrorMessage.title) return customErrorMessage;

    if (!customErrorMessage?.message) customErrorMessage.message = defaultErrorMessage?.message;
    if (!customErrorMessage?.title) customErrorMessage.title = defaultErrorMessage?.title;

    return customErrorMessage;
  }
}

export const errorHandlerService = new ErrorHandlerService(defaultErrorMessages);
