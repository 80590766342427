// Core
import { NavigationGuard } from "vue-router";

// Stores
import pinia from "@/store/pinia";

// Stores
import { useTeamStore } from "@/store/team";
import { useUserStore } from "@/store/user";

const requirePlan: NavigationGuard = async (to, _from, next) => {
  try {
    const userStore = useUserStore(pinia);
    const teamStore = useTeamStore(pinia);

    const infos = userStore.userInfos;
    const onboarding = infos?.onboarding;
    const completed = onboarding?.hasCompleted || onboarding?.hasCompletedStep2;

    if (teamStore.teamIsLeadMachineOnly && completed) {
      if ((to.name as string)?.includes("leads")) {
        next();
      } else {
        next({ name: "leads" });
      }
    } else {
      if (completed) return next();

      const step = onboarding?.currentStep;

      if (step === 6) return next("subscription");
      if (step === 5) return next("meetbase-onboarding");

      return next("sign-up");
    }
  } catch (error) {
    next({ name: "500" });
  }
};

export default requirePlan;
