import { errorHandlerService } from "@/core/services/error";
import { projectAuth } from "../../firebase/config";

type MethodOptions = "PATCH" | "POST" | "GET" | "DELETE" | "PUT";

export const fetchStreamFromNewAPI = async <T = unknown>(
  method: MethodOptions,
  endpointURL: string,
  body: T,
  multipartData?: boolean,
  onData?: (data: { done: boolean; value: string }) => void,
  onDone?: (data: string) => void,
  onError?: (error: unknown) => void,
  signal?: AbortSignal
): Promise<void> => {
  const user = projectAuth.currentUser;
  const token = user && (await user.getIdToken());
  const url = `${import.meta.env.VITE_NEW_API_BASE_URL}/${endpointURL}`;

  let requestBody: BodyInit;
  const headers: HeadersInit = {
    Authorization: `Bearer ${token}`
  };

  if (multipartData) {
    const formData = new FormData();

    for (const key in body) {
      if ((body as object).hasOwnProperty(key)) {
        const value = body[key];

        if (value instanceof File) {
          formData.append(key, value);
        } else if (Array.isArray(value) && value.every(item => item instanceof File)) {
          value.forEach((file: File) => {
            formData.append(`${key}`, file);
          });
        } else if (typeof value === "object" && value !== null) {
          formData.append(key, JSON.stringify(value));
        } else {
          formData.append(key, String(value));
        }
      }
    }

    requestBody = formData;
  } else {
    requestBody = JSON.stringify(body);
    headers["Content-Type"] = "application/json";
  }

  const response = await fetch(url, {
    method,
    headers,
    body: requestBody,
    signal
  });

  if (!response.ok) {
    const errorData = await response.json();

    onError && onError(errorData);

    return;
  }

  if (!response.body) {
    errorHandlerService.handleError("No data");

    return;
  }

  const decoder = new TextDecoder("utf-8");
  const stream = response.body?.getReader();

  let currentChunk = "";

  const read = (): Promise<void | undefined> => {
    return stream
      .read()
      .then(({ done, value }) => {
        if (signal?.aborted) {
          stream.cancel();
          onData && onData({ done: true, value: "" });
          return;
        }

        if (done) {
          onDone && onDone(currentChunk);
          return;
        }

        const stringChunk = decoder.decode(value);
        onData && onData({ done, value: stringChunk });
        currentChunk = stringChunk;

        return read();
      })
      .catch(error => {
        onError && onError(error);
      });
  };
  read();
};

export class ApiStream<T = unknown> {
  private method: MethodOptions;
  private endpointURL: string;
  private body: T;
  private multipartData?: boolean;
  private onDataHandler?: null | ((data: { done: boolean; value: string }) => void) = null;
  private onDoneHandler?: null | ((data: string) => void) = null;
  private onErrorHandler?: null | ((error: unknown) => void) = null;
  private abortController: AbortController;

  constructor(method: MethodOptions, endpointURL: string, body: T, multipartData?: boolean) {
    this.body = body;
    this.method = method;
    this.endpointURL = endpointURL;
    this.multipartData = multipartData;
    this.abortController = new AbortController();
  }

  onData(handler: (data: { done: boolean; value: string }) => void) {
    this.onDataHandler = handler;
    return this;
  }

  onDone(handler: (data: string) => void) {
    this.onDoneHandler = handler;
    return this;
  }

  onError(handler: (error: unknown) => void) {
    this.onErrorHandler = handler;
    return this;
  }

  private fetchStream() {
    fetchStreamFromNewAPI(
      this.method,
      this.endpointURL,
      this.body,
      this.multipartData,
      this.onDataHandler || undefined,
      this.onDoneHandler || undefined,
      this.onErrorHandler || undefined,
      this.abortController.signal
    );
  }

  async start() {
    return this.fetchStream();
  }

  cancel() {
    this.abortController.abort();
  }
}
