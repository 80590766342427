// Libraries
import axios, { AxiosError, AxiosInstance, AxiosResponse } from "axios";

// Services & Helpers
import { projectAuth } from "@/firebase/config";
import { sentryService } from "@/core/services/sentry/sentryService";

// Stores
import { useUserStore } from "@/store/user";

// Types
import { ApiErrorCode, ApiError } from "@/@types/api";

export type ErrorStatus = ApiErrorCode | undefined;

type RequestT = {
  endpoint: string;
  body?: Record<string, unknown>;
};

type CallT = RequestT & {
  method?: "GET" | "POST" | "PUT" | "PATCH" | "DELETE";
};

class ApiService {
  private instance: AxiosInstance;
  private token: string | null = null;

  constructor(client: AxiosInstance) {
    this.instance = client;
    this.intercept();
  }

  async get<T>({ endpoint }: Omit<RequestT, "body">) {
    return this.send<T>({ method: "GET", endpoint });
  }

  async post<T>(data: RequestT) {
    return this.send<T>({ method: "POST", ...data });
  }

  async put<T>(data: RequestT) {
    return this.send<T>({ method: "PUT", ...data });
  }

  async patch<T>(data: RequestT) {
    return this.send<T>({ method: "PATCH", ...data });
  }

  async delete<T>(data: RequestT) {
    return this.send<T>({ method: "DELETE", ...data });
  }

  private async send<T>(
    call: CallT,
    retry: boolean = true
  ): Promise<{ data: T | null; error: ErrorStatus | null; errorData: ApiError | null }> {
    try {
      sentryService.addBreadcrumb("api", `${call.method} ${call.endpoint}`, {
        method: call.method,
        endpoint: call.endpoint,
        hasBody: !!call.body
      });

      const data = await this.fetch<T>(call);

      return { data, error: null, errorData: null };
    } catch (error) {
      const auth = error instanceof AxiosError && error.response?.status === 401;

      if (auth && retry) {
        await this.refresh();
        return this.send<T>(call, false);
      }

      if (error instanceof AxiosError) {
        this.captureAxiosError(error, call);

        return this.treat(error);
      }

      sentryService.captureError(
        error,
        "unexpected-error",
        { title: "Erro Inesperado", message: "Ocorreu um erro inesperado ao processar a requisição" },
        { endpoint: call.endpoint, method: call.method || "unknown" }
      );

      return { data: null, error: "unexpected-error", errorData: null };
    }
  }

  private async fetch<T>({ method, endpoint, body }: CallT) {
    const response: AxiosResponse<T> = await this.instance({ method, url: endpoint, data: body });
    return response.data;
  }

  private async treat(error: AxiosError<ApiError>) {
    return { data: null, error: this.handleError(error), errorData: error.response?.data ?? null };
  }

  async refresh() {
    const token = await projectAuth.currentUser?.getIdToken();

    if (!token) return await this.redirect();

    this.token = token;
  }

  private async redirect() {
    const userStore = useUserStore();

    await userStore.logout();
    window.location.href = "/login";
  }

  intercept(token?: string) {
    this.token = token ?? this.token;

    this.instance.interceptors.request.use(async config => {
      sentryService.captureRequestData({
        url: config.url,
        method: config.method,
        headers: config.headers as Record<string, string>,
        params: config.params as Record<string, string>,
        data: config.data
      });

      config.headers.Authorization = `Bearer ${this.token}`;

      return config;
    });
  }

  private handleError(error: AxiosError<ApiError>): ApiErrorCode {
    if (error.response && error.response.data.error) {
      return error.response.data.error as ApiErrorCode;
    } else if (error.request) {
      return "network-error";
    } else {
      return "unexpected-error";
    }
  }

  private captureAxiosError(error: AxiosError<ApiError>, call: CallT) {
    let userInfo:
      | {
          id: string;
          email: string;
          name: string;
          teamId: string;
          projectId: string;
          role: string;
        }
      | undefined;

    try {
      const userStore = useUserStore();
      const currentUser = userStore.userInfos;

      if (currentUser) {
        userInfo = {
          id: currentUser.id,
          email: currentUser.email,
          name: currentUser.firstName + " " + currentUser.lastName,
          teamId: currentUser.activeTeam?.id,
          projectId: currentUser.lastActiveProject?.id,
          role: currentUser.activeTeam?.role
        };
      }
    } catch (e) {
      // NOTE: Silenciar erro se o userStore não estiver disponível
    }

    if (error.response && error.response.status >= 500) {
      sentryService.captureReplay("Erro critico de servidor", {
        endpoint: call.endpoint,
        method: call.method || "unknown",
        status: error.response.status.toString(),
        errorCode: error.response.data?.error || `http-${error.response.status}`,
        requestBody: JSON.stringify(call.body || {})
      });
    }

    const errorWithBody = {
      ...error,
      data: call.body
    };

    if (error.response) {
      sentryService.captureError(
        errorWithBody,
        error.response.data?.error || `http-${error.response.status}`,
        {
          title: `Erro ${error.response.status}`,
          message: error.response.data?.message || error.message
        },
        {
          endpoint: call.endpoint,
          method: call.method || "unknown",
          status: error.response.status.toString(),
          requestBody: JSON.stringify(call.body || {}),
          error_source: "api"
        },
        userInfo
      );
    } else if (error.request) {
      sentryService.captureError(
        errorWithBody,
        "network-error",
        {
          title: "Erro de Rede",
          message: "A requisição foi enviada, mas não houve resposta do servidor"
        },
        {
          endpoint: call.endpoint,
          method: call.method || "unknown",
          requestBody: JSON.stringify(call.body || {}),
          error_source: "api"
        },
        userInfo
      );
    } else {
      sentryService.captureError(
        errorWithBody,
        "request-config-error",
        { title: "Erro de Configuração", message: error.message },
        {
          endpoint: call.endpoint,
          method: call.method || "unknown",
          requestBody: JSON.stringify(call.body || {}),
          error_source: "api"
        },
        userInfo
      );
    }
  }
}

const baseURL = import.meta.env.VITE_NEW_API_BASE_URL;

const client = axios.create({ baseURL, headers: { "Content-Type": "application/json" } });
const apiService = new ApiService(client);

const multipart = axios.create({ baseURL, headers: { "Content-Type": "multipart/form-data" } });
const apiMultipartService = new ApiService(multipart);

export { apiService, apiMultipartService };
