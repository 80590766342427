// Core
import { ref, computed } from "vue";

// Libaries
// import Hotjar from "@hotjar/browser";
import { type User } from "firebase/auth";
import { updateProfile } from "firebase/auth";
import { projectAuth } from "@/firebase/config";

// Services & Helpers
import { apiService } from "@/core/services/apiService";
import { errorHandlerService } from "@/core/services/error";

// Stores
import { useUserStore } from "@/store/user";
import { useAdminStore } from "@/store/admin";

// Composables
import { useStorage } from "@/composables/useStorage";

// Types
import type { UserDetailsAdmin } from "@/@types/user";

type UserPreferencesT = {
  viewedLatestNews: boolean;
  dailyFirstAccessModal: boolean;
  disableUpdatedProjectFeatureModal?: boolean;
  theme: "light" | "dark";
};

const user = ref<User | null>(projectAuth.currentUser);
const userStatus = ref<UserDetailsAdmin>({} as UserDetailsAdmin);

const updateStatus = async () => {
  const userStore = useUserStore();

  if (!userStore?.userId) {
    userStatus.value = {} as UserDetailsAdmin;
    userStore.userInfos = {} as unknown as UserDetailsAdmin;
    return;
  }

  const result = await apiService.get<UserDetailsAdmin>({ endpoint: `users/${userStore?.userId}` });

  if (result.error || !result.data) {
    errorHandlerService.handleError(result.error);
    userStatus.value = {} as UserDetailsAdmin;
    userStore.userInfos = {} as unknown as UserDetailsAdmin;
    return;
  }

  userStatus.value = result.data;
  userStore.userInfos = result.data;

  // Hotjar.identify(userStore?.userId, {
  //   email: userStore?.userInfos?.email
  // });
};

const updateUserPreferences = async (preferences: Partial<UserPreferencesT>) => {
  if (userStatus.value) {
    userStatus.value.preferences = { ...userStatus.value.preferences, ...preferences };
  }

  const { error } = await apiService.patch({
    endpoint: `users`,
    body: { preferences: { ...preferences } }
  });

  return error;
};

const viewLatestNotification = async () => {
  return updateUserPreferences({
    viewedLatestNews: true
  });
};

const disableUpdatedProjectFeatureModal = async () => {
  return updateUserPreferences({
    disableUpdatedProjectFeatureModal: true
  });
};

const toggleDailyFirstAccessModal = async (value: boolean) => {
  return updateUserPreferences({
    dailyFirstAccessModal: value
  });
};

const setUserStatus = async (status?: UserDetailsAdmin) => {
  const userStore = useUserStore();

  userStatus.value = status ?? ({} as UserDetailsAdmin);
  userStore.userInfos = status ?? ({} as UserDetailsAdmin);

  user.value = projectAuth.currentUser;
};

const updateUserPhoto = async (photoURL: string) => {
  if (!user.value) return;

  const { deleteImage } = useStorage();

  if (user.value.photoURL) deleteImage(user.value.photoURL);

  await updateProfile(user.value, { photoURL });

  user.value = projectAuth.currentUser;
};

const isUserFirstAccess = computed(() => {
  const userOnboardingStatus = userStatus.value?.onboarding;
  return !userOnboardingStatus?.hasCompletedTour && !userOnboardingStatus?.hasCompletedStep2;
});

export const isUserTeamMember = computed(() => {
  const role = userStatus.value?.activeTeam?.role;
  return role !== "admin";
});

const accessToAdmin = async (userIdOrEmail: string) => {
  const userStore = useUserStore();

  userStore.loadUserByIdOrEmail(userIdOrEmail).then(res => {
    userStore.updateUserById({ role: "admin" }, res?.id ?? "");
  });
};

const createUserWithSubscription = async (reqBody: {
  name: string;
  email: string;
  planId: string;
  password?: string;
  expiresAt?: string;
}) => {
  const adminStore = useAdminStore();
  const userStore = useUserStore();

  const body = {
    email: reqBody.email,
    name: reqBody.name,
    password: reqBody.password?.trim() || undefined
  };

  await userStore.signup(body);

  const res = await adminStore.loadTeams(reqBody.email);

  const teamId = res?.teams?.[0]?.id;

  if (!teamId) return;

  const { planId, expiresAt } = reqBody;

  if (!expiresAt) {
    await adminStore.createSubscription({
      planId: planId,
      teamId: teamId
    });
  } else {
    await adminStore.createSubscription({
      planId: planId,
      teamId: teamId,
      trialEnd: expiresAt
    });
  }
};

export const useUser = () => {
  return {
    user,
    userStatus,
    isUserFirstAccess,
    updateStatus,
    setUserStatus,
    updateUserPhoto,
    updateUserPreferences,
    viewLatestNotification,
    toggleDailyFirstAccessModal,
    accessToAdmin,
    createUserWithSubscription,
    disableUpdatedProjectFeatureModal
  };
};
