// Libraries
import posthog from "posthog-js";

// Types
import type { UserDetailsAdmin } from "@/@types/user";

type AnalyticsEventT = {
  name: string;
  properties?: Record<string, unknown>;
};

type AnalyticsProviderT = {
  initialize(): Promise<void>;
  trackEvent(event: AnalyticsEventT): Promise<void>;
  setUser(user: UserDetailsAdmin): Promise<void>;
  incrementUserProperty(property: string, value: number): Promise<void>;
  identify(id: string): Promise<void>;
  reset(): Promise<void>;
};

class PostHogProvider implements AnalyticsProviderT {
  private posthogInitialized: boolean = false;
  private initializationPromise: Promise<void> | null = null;
  private posthogApiKey: string;
  private apiHost: string;

  constructor(posthogApiKey: string, apiHost: string = "https://us.i.posthog.com") {
    this.posthogApiKey = posthogApiKey;
    this.apiHost = apiHost;
  }

  async initialize(): Promise<void> {
    console.info("Iniciando provedor PostHog...");

    if (posthog.__loaded) {
      console.info("PostHog já está inicializado por outro componente, usando instância existente.");

      this.posthogInitialized = true;

      return Promise.resolve();
    }

    if (this.initializationPromise) {
      return this.initializationPromise;
    }

    this.initializationPromise = new Promise<void>((resolve, reject) => {
      try {
        posthog.init(this.posthogApiKey, {
          api_host: this.apiHost,
          debug: import.meta.env.VITE_NODE_ENV !== "production",
          loaded: () => {
            this.posthogInitialized = true;

            if (import.meta.env.VITE_NODE_ENV !== "production") {
              console.info("PostHog carregado com sucesso");
            }

            resolve();
          }
        });
      } catch (error) {
        console.error("Erro ao inicializar PostHog:", error);
        reject(error);
      }
    });

    return this.initializationPromise;
  }

  private async ensureInitialized(): Promise<void> {
    if (!this.posthogInitialized) {
      await this.initialize();
    }
  }

  async trackEvent(event: AnalyticsEventT): Promise<void> {
    await this.ensureInitialized();

    posthog.capture(event.name, event.properties);
  }

  async setUser(user: UserDetailsAdmin): Promise<void> {
    await this.ensureInitialized();

    posthog.identify(user.id, {
      name: `${user.firstName} ${user.lastName}`,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      phoneNumber: user.phoneNumber,
      teamId: user.activeTeam?.id,
      teamName: user.activeTeam?.name,
      teamRole: user.activeTeam?.role,
      preferences: user.preferences,
      lastUpdate: user.updatedAt ? new Date(user.updatedAt).toISOString() : "",
      signUpDate: new Date(user.createdAt).toISOString()
    });
  }

  async incrementUserProperty(property: string, value: number): Promise<void> {
    await this.ensureInitialized();

    posthog.capture("$set", {
      [property]: value
    });
  }

  async identify(id: string): Promise<void> {
    await this.ensureInitialized();

    posthog.identify(id);
  }

  async reset(): Promise<void> {
    await this.ensureInitialized();

    posthog.reset();
  }
}

class AnalyticsService {
  private static instance: AnalyticsService;
  private provider: AnalyticsProviderT;

  private constructor(provider: AnalyticsProviderT) {
    this.provider = provider;
  }

  static async getInstance(posthogApiKey: string, apiHost?: string): Promise<AnalyticsService> {
    if (!this.instance) {
      const provider = new PostHogProvider(posthogApiKey, apiHost);

      await provider.initialize();

      this.instance = new AnalyticsService(provider);
    }

    return this.instance;
  }

  async trackEvent(event: AnalyticsEventT): Promise<void> {
    await this.provider.trackEvent(event);
  }

  async setUser(user: UserDetailsAdmin): Promise<void> {
    await this.provider.setUser(user);
  }

  async incrementUserProperty(property: string, value: number): Promise<void> {
    await this.provider.incrementUserProperty(property, value);
  }

  async identify(id: string): Promise<void> {
    await this.provider.identify(id);
  }

  async reset(): Promise<void> {
    await this.provider.reset();
  }
}

let analyticsInstance: AnalyticsService | null = null;

export const initializeAnalytics = async (): Promise<void> => {
  console.info("Iniciando analytics...");

  const posthogApiKey =
    import.meta.env.VITE_NODE_ENV === "production"
      ? import.meta.env.VITE_POSTHOG_PROD_KEY
      : import.meta.env.VITE_POSTHOG_DEV_KEY;

  console.info("Usando chave:", posthogApiKey ? "Chave configurada" : "Chave não encontrada");

  analyticsInstance = await AnalyticsService.getInstance(posthogApiKey);

  console.info("Analytics inicializado:", !!analyticsInstance);
};

export const analyticsTrackEvent = (event: AnalyticsEventT): void => {
  console.info("Tentando enviar evento:", event);

  try {
    posthog.capture(event.name, event.properties);

    console.info("Evento enviado com sucesso");
  } catch (error) {
    console.error("Erro ao enviar evento:", error);
  }
};

export const analyticsSetUser = async (user: UserDetailsAdmin): Promise<void> => {
  if (!analyticsInstance) {
    console.warn("Analytics não inicializado. Inicialize primeiro com initializeAnalytics().");

    try {
      posthog.identify(user.id, {
        name: `${user.firstName} ${user.lastName}`,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        phoneNumber: user.phoneNumber,
        teamId: user.activeTeam?.id,
        teamName: user.activeTeam?.name,
        teamRole: user.activeTeam?.role,
        preferences: user.preferences,
        lastUpdate: user.updatedAt ? new Date(user.updatedAt).toISOString() : "",
        signUpDate: new Date(user.createdAt).toISOString()
      });
    } catch (error) {
      console.error("Erro ao tentar identificar usuário diretamente:", error);
    }
    return;
  }

  await analyticsInstance.setUser(user);
};

export const analyticsIncrementUserProperty = async (property: string, value: number): Promise<void> => {
  if (!analyticsInstance) {
    console.warn("Analytics não inicializado. Inicialize primeiro com initializeAnalytics().");

    try {
      posthog.capture("$set", {
        [property]: value
      });
    } catch (error) {
      console.error("Erro ao incrementar propriedade diretamente:", error);
    }
    return;
  }

  await analyticsInstance.incrementUserProperty(property, value);
};

export const analyticsIdentify = async (id: string): Promise<void> => {
  if (!analyticsInstance) {
    console.warn("Analytics não inicializado. Inicialize primeiro com initializeAnalytics().");

    try {
      posthog.identify(id);
    } catch (error) {
      console.error("Erro ao identificar usuário diretamente:", error);
    }
    return;
  }

  await analyticsInstance.identify(id);
};

export const analyticsReset = async (): Promise<void> => {
  if (!analyticsInstance) {
    console.warn("Analytics não inicializado. Inicialize primeiro com initializeAnalytics().");

    try {
      posthog.reset();
    } catch (error) {
      console.error("Erro ao resetar diretamente:", error);
    }
    return;
  }

  await analyticsInstance.reset();
};
