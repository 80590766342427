// Core
import { App } from "vue";

// Libraries
import posthog from "posthog-js";

/** NOTE:
 * Plugin Vue para o PostHog
 * Pode ser registrado na aplicação usando app.use(posthogPlugin)
 */
export default {
  install(app: App) {
    const apiKey =
      import.meta.env.VITE_NODE_ENV === "production"
        ? import.meta.env.VITE_POSTHOG_PROD_KEY
        : import.meta.env.VITE_POSTHOG_DEV_KEY;

    const apiHost = import.meta.env.VITE_POSTHOG_API_HOST || "https://us.i.posthog.com";

    // NOTE: Inicializa o PostHog
    posthog.init(apiKey, {
      api_host: apiHost,
      autocapture: false,
      capture_pageview: false,
      loaded: function () {
        if (import.meta.env.VITE_NODE_ENV !== "production") {
          console.log("PostHog carregado com sucesso via plugin");
        }
      }
    });

    // NOTE: Disponibiliza posthog como uma propriedade global
    app.config.globalProperties.$posthog = posthog;

    // NOTE: Define um tipo para disponibilizar o $posthog no TypeScript
    type PostHogInstance = typeof posthog;

    // NOTE: Disponibiliza para a API de composição do Vue
    app.provide("posthog", posthog);
  }
};
