import { ErrorTable } from "./types";

export const defaultErrorMessages: Partial<ErrorTable> = {
  "auth/wrong-password": {
    title: "Senha incorreta",
    message: "A senha fornecida está incorreta. Verifique e tente novamente."
  },
  "anthropic-bad-request": {
    title: "Oops, confira os dados!",
    message: "Parece que algo não foi enviado corretamente para a Anthropic. Verifique e tente novamente."
  },
  "bad-request": {
    title: "Algo está faltando!",
    message: "Confira se todos os campos estão preenchidos corretamente e tente novamente."
  },
  "email-is-disposable": {
    title: "E-mail temporário detectado",
    message: "Use um e-mail permanente para continuar. Vamos lá!"
  },
  "document-is-too-long": {
    title: "Documento grande demais",
    message: "Reduza o tamanho do documento para prosseguir. Você consegue!"
  },
  "email-already-exists": {
    title: "E-mail já em uso",
    message: "Este e-mail já está registrado. Tente um diferente ou faça login."
  },
  "image-too-large": {
    title: "Imagem grande demais",
    message: "Reduza o tamanho da imagem para continuar. Vai valer a pena!"
  },
  "image-size": {
    title: "Erro de tamanho",
    message: "Erro relacionado com as dimensões da imagem."
  },
  "invalid-ip-address": {
    title: "IP inválido",
    message: "Confira e insira um IP válido para prosseguir."
  },
  "invalid-pdf-file-url": {
    title: "URL de PDF inválida",
    message: "Verifique a URL do PDF e tente novamente."
  },
  "invalid-url": {
    title: "URL inválida",
    message: "Link inválido."
  },
  "invalid-youtube-video-url": {
    title: "URL do YouTube inválida",
    message: "Confira a URL do YouTube e tente novamente. Estamos quase lá!"
  },
  "not-enough-content": {
    title: "Precisamos de mais conteúdo",
    message: "Adicione um pouco mais de conteúdo para prosseguir."
  },
  "openai-bad-request": {
    title: "Oops, confira os dados!",
    message: "Algo não foi enviado corretamente para a OpenAI. Verifique e tente novamente."
  },
  "openai-max-tokens": {
    title: "Limite de tokens excedido",
    message: "Reduza o tamanho do conteúdo e tente novamente."
  },
  "parent-folder-is-a-child-folder": {
    title: "Pasta dentro de pasta",
    message: "Escolha uma pasta diferente que não seja subpasta."
  },
  "phone-number-already-exists": {
    title: "Número já registrado",
    message: "Este número já está em uso. Por favor, verifique seu número ou tente outro."
  },
  "sd-invalid-prompt": {
    title: "Prompt inválido",
    message: "Vamos ajustar o prompt para a geração de imagem e tentar novamente!"
  },
  "team-user-status-is-not-pending": {
    title: "Status do usuário incompatível",
    message: "O status deste usuário no time não está pendente. Verifique e tente novamente."
  },
  "user-already-requested-to-cancel": {
    title: "Cancelamento já solicitado",
    message: "Sua solicitação de cancelamento já foi registrada. Aguarde nossa resposta."
  },
  "user-credit-card-acquirer": {
    title: "Problema com cartão de crédito",
    message: "Verifique os detalhes do cartão de crédito e tente novamente."
  },
  "user-is-already-in-team": {
    title: "Usuário já no time",
    message: "Este usuário já faz parte do time. Vamos em frente!"
  },
  "video-is-too-long": {
    title: "Vídeo muito longo",
    message: "Reduza o vídeo para prosseguir."
  },
  "anthropic-authentication": {
    title: "Probleminha de autenticação",
    message: "Houve um erro de autenticação com a Anthropic. Tente novamente ou contate o suporte."
  },
  "openai-authentication": {
    title: "Autenticação necessária",
    message: "Precisamos autenticar novamente na OpenAI. Faça login e continue a jornada."
  },
  "token-has-expired": {
    title: "Sessão expirada",
    message: "Sua sessão expirou. Faça login novamente para continuar."
  },
  "invalid-token": {
    title: "Token inválido",
    message: "Seu token de autenticação parece inválido. Faça login novamente."
  },
  "token-not-found": {
    title: "Token não encontrado",
    message: "Não encontramos seu token de autenticação. Faça login para prosseguir."
  },
  "anthropic-forbidden": {
    title: "Acesso negado na Anthropic",
    message: "Você não tem permissão para esta ação na Anthropic. Verifique suas credenciais."
  },
  "cant-change-own-data-in-team": {
    title: "Alteração de dados restrita",
    message: "Não é possível alterar seus próprios dados no time. Peça ajuda a um administrador."
  },
  "cant-change-status-from-pending": {
    title: "Mudança de status bloqueada",
    message: "O status 'pending' não pode ser alterado diretamente. Contate o administrador do time."
  },
  "cant-create-onboarding-copy": {
    title: "Criação de copy bloqueada",
    message: "Não é possível gerar copies no onboarding após sua conclusão."
  },
  "cant-delete-default-folder": {
    title: "Pasta padrão protegida",
    message: "A pasta padrão não pode ser apagada. Ela é essencial para a organização do projeto."
  },
  "cant-delete-default-project": {
    title: "Projeto padrão indelável",
    message: "O projeto padrão é necessário. Crie ou use outros projetos para suas necessidades."
  },
  "cant-delete-news-or-warning-notification": {
    title: "Notificação importante",
    message: "Notificações de notícias e avisos não podem ser apagadas. Elas são importantes!"
  },
  "cant-delete-yourself-from-team": {
    title: "Remoção do time",
    message: "Não é possível se remover do time. Peça a um administrador para fazer isso."
  },
  "cant-update-default-project-name": {
    title: "Nome do projeto padrão fixo",
    message: "O nome do projeto padrão não pode ser alterado. Crie um novo projeto com o nome desejado."
  },
  "cant-use-chat": {
    title: "Chat indisponível",
    message: "O chat não está disponível para você no momento. Verifique suas permissões ou planos."
  },
  "cant-use-lp-vsl-pptx": {
    title: "Geração de PPTX bloqueada",
    message: "A geração de PPTX não está disponível para você. Verifique seu plano ou permissões."
  },
  "cant-use-trial": {
    title: "Trial indisponível",
    message: "O período de teste já foi utilizado ou não está disponível. Confira nossos planos!"
  },
  "forbidden-resource": {
    title: "Recurso restrito",
    message: "Você não tem permissão para acessar este recurso. Verifique suas credenciais ou planos."
  },
  "not-enough-team-credits": {
    title: "Créditos insuficientes no time",
    message: "Seu time não tem créditos suficientes. Recarregue ou ajuste o uso para continuar."
  },
  "not-enough-team-image-credits": {
    title: "Créditos de imagem insuficientes no time",
    message: "Adicione mais créditos de imagem ao time para continuar criando."
  },
  "not-enough-team-permission": {
    title: "Permissão de time insuficiente",
    message: "Esta ação requer permissões adicionais no time. Consulte um administrador."
  },
  "not-enough-team-plagiarism-credits": {
    title: "Créditos de plágio insuficientes no time",
    message: "Precisamos de mais créditos para usar o detector de plágio. Hora de recarregar!"
  },
  "not-enough-user-credits": {
    title: "Créditos insuficientes",
    message: "Você precisa de mais créditos para prosseguir. Vamos adicionar mais?"
  },
  "not-enough-user-image-credits": {
    title: "Créditos de imagem insuficientes",
    message: "Adicione créditos de imagem para continuar a sua jornada criativa."
  },
  "not-enough-user-plagiarism-credits": {
    title: "Créditos de plágio insuficientes",
    message: "Adicione créditos para usar o detector de plágio e garantir originalidade."
  },
  "openai-permission-denied": {
    title: "Permissão negada na OpenAI",
    message: "Você não tem permissão para esta ação na OpenAI. Verifique suas credenciais."
  },
  "subscription-not-from-pagarme": {
    title: "Assinatura não identificada",
    message: "Sua assinatura não parece ser do Pagarme. Verifique e tente novamente."
  },
  "team-is-not-active": {
    title: "Time inativo",
    message: "Seu time não está ativo no momento. Fale com o administrador para reativá-lo."
  },
  "team-is-not-in-trial": {
    title: "Trial do time finalizado",
    message: "Seu time não está mais no período de teste. Confira nossos planos disponíveis."
  },
  "team-user-is-not-active": {
    title: "Usuário do time inativo",
    message: "Este usuário do time não está ativo. Fale com o administrador para mais detalhes."
  },
  "team-users-limit-exceeded": {
    title: "Limite de usuários excedido",
    message: "Seu time atingiu o limite de usuários. Remova alguém ou atualize seu plano."
  },
  "user-is-not-team-admin": {
    title: "Administração do time necessária",
    message: "Essa ação requer status de administrador do time. Fale com o administrador."
  },
  "anthropic-not-found": {
    title: "Recurso não encontrado na Anthropic",
    message: "Não conseguimos encontrar o recurso solicitado na Anthropic. Verifique e tente novamente."
  },
  "openai-not-found": {
    title: "Recurso não encontrado na OpenAI",
    message: "O recurso solicitado não foi encontrado na OpenAI. Dê uma olhada e tente novamente."
  },
  "resource-not-found": {
    title: "Recurso não encontrado",
    message: "Parece que não conseguimos encontrar o recurso. Verifique se está correto e tente de novo."
  },
  "subscription-id-not-found": {
    title: "ID de Assinatura não encontrado",
    message: "Não encontramos uma assinatura com esse ID. Verifique e tente novamente."
  },
  "subscription-not-found": {
    title: "Assinatura não encontrada",
    message: "Não conseguimos localizar sua assinatura. Confira seus detalhes ou entre em contato."
  },
  "openai-unprocessable-entity": {
    title: "Requisição não processável na OpenAI",
    message: "Algo na sua requisição não pode ser processado pela OpenAI. Revise e tente novamente."
  },
  "anthropic-rate-limit": {
    title: "Limite de requisições na Anthropic",
    message: "Atingimos o limite de requisições na Anthropic. Aguarde um pouco e tente novamente."
  },
  "openai-rate-limit": {
    title: "Limite de requisições na OpenAI",
    message: "Atingimos o limite de requisições na OpenAI. Vamos tentar novamente em breve?"
  },
  "plan-change-last-five-minutes": {
    title: "Mudança recente de plano",
    message: "Você mudou de plano recentemente. Aguarde um pouco antes de tentar novamente."
  },
  "client-closed-request": {
    title: "Conexão encerrada",
    message: "Parece que a solicitação foi cancelada. Se precisar, tente de novo."
  },
  "internal-server-error": {
    title: "Ops, problema interno",
    message: "Encontramos um erro interno. Estamos trabalhando para resolver isso. Tente mais tarde."
  },
  "anthropic-api-overloaded": {
    title: "API da Anthropic sobrecarregada",
    message: "A API da Anthropic está sobrecarregada agora. Tente novamente mais tarde."
  },
  "anthropic-bad-gateway": {
    title: "Problema na conexão com a Anthropic",
    message: "Houve um problema na conexão com a API da Anthropic. Vamos tentar novamente?"
  },
  "could-not-access-file-url": {
    title: "URL do arquivo inacessível",
    message: "Não conseguimos acessar a URL do arquivo. Verifique se está correta e tente novamente."
  },
  "could-not-access-pdf-url": {
    title: "URL do PDF inacessível",
    message: "Não conseguimos acessar a URL do PDF. Verifique se está correta e tente novamente."
  },
  "openai-bad-gateway": {
    title: "Problema na conexão com a OpenAI",
    message: "Houve um problema na conexão com a API da OpenAI. Vamos tentar novamente?"
  },
  "pinecone-bad-gateway": {
    title: "Problema na conexão com a Pinecone",
    message: "Houve um problema na conexão com a API da Pinecone. Vamos tentar novamente?"
  },
  "stable-diffusion-timeout": {
    title: "Tempo esgotado na Stable Diffusion",
    message: "A solicitação na Stable Diffusion demorou demais. Tente novamente em breve."
  },
  "upgrade-error-0000": {
    title: "Erro desconhecido no upgrade",
    message: "Encontramos um erro não identificado durante o upgrade. Tente novamente ou contate o suporte."
  },
  "upgrade-error-0001": {
    title: "Erro no endereço durante o upgrade",
    message: "Houve um problema com o endereço fornecido no upgrade. Verifique e tente novamente."
  },
  "upgrade-error-0002": {
    title: "Saldo insuficiente para o upgrade",
    message: "Verifique o saldo para completar o upgrade. Se precisar de ajuda, estamos aqui."
  },
  "upgrade-error-0003": {
    title: "Limite insuficiente para o upgrade",
    message: "Seu limite atual não permite o upgrade. Verifique suas configurações financeiras."
  },
  "upgrade-error-0004": {
    title: "Upgrade bloqueado pelo banco",
    message: "O banco bloqueou o upgrade. Entre em contato com ele para mais informações."
  },
  "openai-model-overloaded": {
    title: "Modelo da OpenAI sobrecarregado",
    message: "O modelo da OpenAI está com alta demanda agora. Tente novamente mais tarde."
  },
  "stable-diffusion-service-unavailable": {
    title: "Stable Diffusion indisponível",
    message: "A Stable Diffusion está indisponível no momento. Vamos tentar novamente depois."
  },
  "invalid-email": {
    title: "E-mail inválido",
    message: "Parece que o e-mail não é válido. Dê uma olhada e tente novamente."
  },
  "invalid-file-type": {
    title: "Tipo de arquivo não suportado",
    message: "Use tipos de arquivo válidos: {{types}}. Confira e tente novamente."
  },
  "black-listed-domain": {
    title: "Domínio em Blacklist",
    message:
      "O domínio do e-mail está em nossa blacklist. Por favor, use um e-mail diferente ou entre em contato conosco."
  },
  "rate-limit": {
    title: "Limite de requisições excedido",
    message: "Você excedeu o limite de requisições deste endpoint. Aguarde um pouco e tente novamente."
  },
  "invalid-instagram-video-url": {
    title: "Link inválido",
    message: "O link do vídeo parece não estar correto. Verifique e tente de novo, por favor."
  },
  "invalid-tiktok-video-url": {
    title: "Link inválido",
    message: "O link do vídeo parece não estar correto. Verifique e tente de novo, por favor."
  },
  "blocked-url": {
    title: "Link bloqueado",
    message: "O link fornecido foi bloqueado. Verifique se o link é válido e tente novamente."
  },
  "insufficient-words": {
    title: "Conteúdo insuficiente",
    message: "O link não possui conteúdo suficiente para a extração dos dados."
  },
  "auth/invalid-email": {
    title: "E-mail inválido",
    message: "O e-mail inserido não é válido. Por favor, verifique e tente novamente."
  },
  "auth/user-disabled": {
    title: "Conta desativada",
    message: "Esta conta foi desativada. Entre em contato com o suporte para mais informações."
  },
  "auth/user-not-found": {
    title: "Conta não encontrada",
    message: "Não encontramos uma conta com este e-mail. Verifique o e-mail ou crie uma nova conta."
  },
  "auth/too-many-requests": {
    title: "Muitas tentativas de login",
    message: "Por favor, tente novamente mais tarde ou redefina sua senha."
  },
  "auth/network-request-failed": { title: "Erro de conexão", message: "Verifique sua internet e tente novamente." },
  "Name must contain a first and last name": {
    title: "Nome inválido",
    message: "Por favor, insira um nome completo (nome e sobrenome)."
  },
  default: {
    title: "Ops! Algo de errado",
    message: "Encontramos um erro. Tente novamente mais tarde."
  }
};
