// Core
import { NavigationGuard } from "vue-router";

// Stores
import pinia from "@/store/pinia";

// Services & Helpers
import { analyticsSetUser } from "@/core/services/analyticsService";
import { projectAuth } from "@/firebase/config";

// Stores
import { useUserStore } from "@/store/user";

const requireAuth: NavigationGuard = async (to, _from, next) => {
  try {
    const userStore = useUserStore(pinia);

    const refresh = projectAuth.currentUser?.refreshToken;

    if (!refresh) {
      next({ name: "login" });
    } else {
      if (userStore.userInfos && to.fullPath !== "/sign-up" && to.fullPath !== "/login") {
        analyticsSetUser(userStore.userInfos);
      }

      next();
    }
  } catch (error) {
    next({ name: "500" });
  }
};

export default requireAuth;
