// Core
import { DirectiveBinding, nextTick } from "vue";

export const vFocus = {
  async mounted(el: HTMLElement, binding: DirectiveBinding) {
    if (binding.value !== false) {
      await nextTick();
      el.focus();
    }
  },
  async updated(el: HTMLElement, binding: DirectiveBinding) {
    if (binding.value !== false) {
      await nextTick();
      el.focus();
    }
  }
};
