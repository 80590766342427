// Core
import { nextTick } from "vue";

// Services & Helpers
import { apiService } from "@/core/services/apiService";

// Composables
import { toast } from "@/composables/useCustomToast";

// Stores
import { defineStore } from "pinia";

// Types
import type {
  AdminStateT,
  AdminFeatureT,
  AdminTeamsResponseT,
  AdminBonusesResponseT,
  AdminSubscriptionsResponseT
} from "@/@types/admin";
import type { Team } from "@/@types/team";
import type { ValidationRuleT } from "@/@types/validation";

export const useAdminStore = defineStore("admin", {
  state: (): AdminStateT => ({
    form: {
      dateRange: null,
      team: {
        value: "",
        error: false,
        errorTxt: "",
        validate(validationRules: ValidationRuleT[]) {
          for (const { test, message } of validationRules) {
            if (!test(this.value)) {
              this.error = true;
              this.errorTxt = message;
              return;
            }
          }

          this.error = false;
          this.errorTxt = "";
        }
      },
      email: {
        value: "",
        error: false,
        errorTxt: "",
        validate(validationRules: ValidationRuleT[]) {
          for (const { test, message } of validationRules) {
            if (!test(this.value)) {
              this.error = true;
              this.errorTxt = message;
              return;
            }
          }

          this.error = false;
          this.errorTxt = "";
        }
      }
    }
  }),
  actions: {
    async loadTeamSubscriptions(
      id: string,
      isBonus?: boolean,
      subscriptionId?: string
    ): Promise<AdminSubscriptionsResponseT | AdminBonusesResponseT | null> {
      const queryParams = new URLSearchParams({
        teamId: id,
        ...(subscriptionId ? { subscriptionId } : {})
      });

      const endpoint = `admin/subscriptions${isBonus ? "/bonuses" : ""}?${queryParams.toString()}`;

      const res = await apiService.get<AdminSubscriptionsResponseT | AdminBonusesResponseT>({
        endpoint
      });

      return res.error ? null : res.data;
    },

    async loadTeams(email?: string, teamName?: string): Promise<AdminTeamsResponseT | null> {
      const queryParams = new URLSearchParams({
        ...(!!teamName ? { teamName } : {}),
        ...(!!email ? { email } : {})
      });

      const res = await apiService.get<AdminTeamsResponseT>({
        endpoint: `admin/teams?${queryParams.toString()}`
      });

      return res.error ? null : res.data;
    },

    async copyToClipboard(textToCopy: string): Promise<void> {
      try {
        await navigator.clipboard.writeText(textToCopy);
      } catch (err) {
        console.error("Erro:", err);
      }
    },

    async loadTeamById(id: string, startDate?: string, endDate?: string): Promise<Team | null> {
      const res = await apiService.get<Team>({
        endpoint:
          startDate && endDate ? `admin/teams/${id}?startDate=${startDate}&endDate=${endDate}` : `admin/teams/${id}`
      });

      return res.error ? null : res.data;
    },

    async renewTeamsCredits(teamId: string): Promise<{} | null> {
      const res = await apiService.post<{}>({ endpoint: `teams/${teamId}/renew` });

      if (!res.error) {
        toast.success({
          title: "Créditos renovados",
          message: "Os créditos da equipe foram renovados com sucesso"
        });
        await nextTick();
        return res.data;
      } else {
        toast.error({
          title: "Algo deu errado",
          message: "Não foi possível renovar os créditos da equipe"
        });
        return null;
      }
    },

    async createSubscription(reqBody: { trialEnd?: string; teamId: string; planId: string }): Promise<{}> {
      try {
        const res = await apiService.post<{}>({ endpoint: `/admin/subscriptions`, body: reqBody });

        if (!res.error && res.data) {
          return res.data;
        }

        throw new Error(res.error || "Failed to create subscription");
      } catch (error) {
        throw error instanceof Error ? error : new Error("Erro desconhecido ao criar assinatura");
      }
    },

    async createBonusForSubscription(
      subscriptionId: string,
      reqBody: { featureId: string; amount?: number; expiresAt: string }[]
    ): Promise<{} | null> {
      try {
        const res = await apiService.post<{}>({
          endpoint: `/admin/subscriptions/${subscriptionId}/bonuses`,
          body: { features: reqBody }
        });

        if (!res.error && res.data) {
          toast.success({
            title: "Bônus criado",
            message: "O bônus foi criado com sucesso!"
          });
        }
        return res.data;
      } catch (error) {
        toast.error({
          title: "Algo deu errado",
          message: "Não foi possível criar o bônus"
        });
        return null;
      }
    },

    async updateSubscription(
      subscriptionId: string,
      reqBody: {
        status?: string;
        planId?: string;
        features?: Record<string, AdminFeatureT>;
      }
    ): Promise<{} | null> {
      try {
        const res = await apiService.patch<{}>({
          endpoint: `/admin/subscriptions/${subscriptionId}`,
          body: reqBody
        });

        if (!res.error) {
          toast.success({
            title: "Assinatura alterada!",
            message: "A assinatura foi alterada com sucesso!"
          });
        }
        return res.data;
      } catch (error) {
        toast.error({
          title: "Algo deu errado",
          message: "Não foi possível alterar a assinatura"
        });
        return null;
      }
    }
  }
});
