// Libraries
import { withScope, captureException, addBreadcrumb, captureMessage, SeverityLevel } from "@sentry/vue";

type NetworkInformationT = {
  type?: string;
  effectiveType?: string;
  downlink?: number;
  rtt?: number;
};

type NavigatorUADataT = {
  platform: string;
  brands: Array<{ brand: string; version: string }>;
  mobile: boolean;
};

declare global {
  interface Navigator {
    connection?: NetworkInformationT;
    userAgentData?: NavigatorUADataT;
  }
}

export class SentryService {
  constructor() {
    this.setupGlobalErrorHandlers();
  }

  private parseQueryParams(url?: string): Record<string, string> | null {
    if (!url) return null;

    try {
      const queryString = url.includes("?") ? url.split("?")[1] : null;

      if (!queryString) return null;

      const cleanQuery = queryString.includes("#") ? queryString.split("#")[0] : queryString;

      const params: Record<string, string> = {};
      const searchParams = new URLSearchParams(cleanQuery);

      for (const [key, value] of searchParams.entries()) {
        params[key] = value;
      }

      return Object.keys(params).length > 0 ? params : null;
    } catch (e) {
      console.error("Erro ao analisar parâmetros de query:", e);

      return null;
    }
  }

  private sanitizeHeaders(headers: Record<string, string>): Record<string, string> {
    const sanitized = { ...headers };
    const sensitiveHeaders = ["authorization", "cookie", "x-auth-token"];

    for (const header of sensitiveHeaders) {
      if (header.toLowerCase() in sanitized) {
        sanitized[header] = "[REDACTED]";
      }
    }

    return sanitized;
  }

  private sanitizePayload(data: Record<string, unknown> | unknown[] | unknown): any {
    if (data === null || data === undefined) return data;

    if (Array.isArray(data)) {
      return data.map(item => this.sanitizePayload(item));
    }

    if (typeof data === "object" && !Array.isArray(data)) {
      const sensitiveFields = ["password", "token", "secret", "credit_card", "cvv", "pin", "cpf", "cnpj", "cpf_cnpj"];
      const sanitized: Record<string, unknown> = {};

      for (const [key, value] of Object.entries(data)) {
        if (sensitiveFields.includes(key)) {
          sanitized[key] = "[REDACTED]";
        } else if (Array.isArray(value)) {
          sanitized[key] = this.sanitizePayload(value);
        } else if (typeof value === "object" && value !== null) {
          sanitized[key] = this.sanitizePayload(value);
        } else {
          sanitized[key] = value;
        }
      }

      return sanitized;
    }

    return data;
  }

  setupGlobalErrorHandlers(): void {
    window.addEventListener("unhandledrejection", event => {
      withScope(scope => {
        scope.setTag("Error Type", "Unhandled Promise Rejection");
        scope.setExtra("Reason", event.reason);
        captureException(event.reason);
      });
    });

    window.addEventListener("error", event => {
      withScope(scope => {
        scope.setTag("Error Type", "Global Error");
        scope.setExtra("Filename", event.filename);
        scope.setExtra("Line Number", event.lineno);
        scope.setExtra("Column Number", event.colno);
        captureException(event.error);
      });
    });
  }

  addBreadcrumb(category: string, message: string, data?: Record<string, any>, level: SeverityLevel = "info"): void {
    addBreadcrumb({
      category,
      message,
      data,
      level: level as SeverityLevel,
      timestamp: Date.now() / 1000
    });
  }

  captureRequestData(
    request: {
      url?: string;
      method?: string;
      headers?: Record<string, string>;
      params?: Record<string, string>;
      data?: Record<string, unknown>;
    },
    shouldCapture: boolean = true
  ): void {
    withScope(scope => {
      const sanitizedPayload = this.sanitizePayload(request.data || {});
      const payloadStr = JSON.stringify(sanitizedPayload, null, 2);
      const paramsStr = request.params ? JSON.stringify(request.params, null, 2) : "{}";

      scope.setContext("Detalhes da Requisicao", {
        url: request.url,
        method: request.method,
        headers: this.sanitizeHeaders(request.headers || {}),
        params_json: paramsStr,
        payload_json: payloadStr
      });

      const queryParams = this.parseQueryParams(request.url);

      if (queryParams) {
        const queryParamsStr = JSON.stringify(queryParams, null, 2);
        scope.setContext("Parametros de Query", {
          url: request.url,
          params_json: queryParamsStr
        });
      }

      if (shouldCapture) {
        captureMessage("API Request");
      }
    });
  }

  captureNetworkError(
    error: {
      request?: {
        readyState?: number;
        status?: number;
        responseURL?: string;
        getAllResponseHeaders?: () => Record<string, string>;
      };
      response?: { status?: number; statusText?: string; data?: Record<string, unknown> };
    },
    shouldCapture: boolean = true
  ): void {
    withScope(scope => {
      scope.setTag("Tipo de Erro", "Rede");
      scope.setTag("Status da Conexao", navigator.onLine ? "online" : "offline");

      scope.setContext("Detalhes da Conexao", {
        type: navigator.connection?.type,
        effectiveType: navigator.connection?.effectiveType,
        downlink: navigator.connection?.downlink,
        rtt: navigator.connection?.rtt
      });

      scope.setContext("Detalhes da Requisicao", {
        readyState: error.request?.readyState,
        status: error.request?.status,
        responseURL: error.request?.responseURL,
        responseHeaders: error.request?.getAllResponseHeaders?.() || null
      });

      const responseData = this.sanitizePayload(error.response?.data || {});
      const responseDataStr = JSON.stringify(responseData, null, 2);

      scope.setContext("Detalhes da Resposta", {
        status: error.response?.status,
        statusText: error.response?.statusText,
        data_json: responseDataStr
      });

      if (shouldCapture) {
        let errorName = "Erro de Rede";

        if (error.response && typeof error.response === "object" && "status" in error.response) {
          errorName = `[Status ${error.response.status}] ${errorName}`;
        }

        if (error.response && typeof error.response === "object" && "statusText" in error.response) {
          errorName += `: ${error.response.statusText}`;
        }

        const errorObj = new Error(errorName);

        if (error instanceof Error) {
          errorObj.stack = error.stack;

          (errorObj as unknown as Error).cause = error;
        }

        captureException(errorObj);
      }
    });
  }

  captureError(
    error: unknown,
    errorCode?: string,
    errorMessage?: { title?: string; message?: string },
    params?: Record<string, string>,
    userInfo?: { id?: string; email?: string; name?: string; teamId?: string; projectId?: string; role?: string }
  ): void {
    withScope(scope => {
      let requestBody: Record<string, unknown> | null = null;

      if (typeof error === "object" && error !== null) {
        if ("data" in error) {
          requestBody = error.data as Record<string, unknown>;
        }
      }

      if (params?.error_source) {
        scope.setTag("error_source", params.error_source);
      }

      scope.setTag("Codigo do Erro", errorCode || "unknown");

      const responseStr = errorMessage ? JSON.stringify(errorMessage, null, 2) : "null";
      const requestStr = requestBody ? JSON.stringify(this.sanitizePayload(requestBody), null, 2) : "{}";

      scope.setExtra("Dados da resposta (JSON)", responseStr);
      scope.setExtra("Dados da requisicao (JSON)", requestStr);

      scope.setTag("Origem do Erro", "API");
      scope.setTag("Ambiente", import.meta.env?.MODE || "unknown");
      scope.setTag("Versao da Aplicacao", import.meta.env?.VITE_APP_VERSION || "unknown");
      scope.setTag("Replay ID", `replay_${Date.now()}_${Math.random().toString(36).substring(2, 9)}`);
      scope.setTag("Tem Replay", "true");

      scope.setContext("Timestamp", {
        time: new Date().toISOString(),
        localTime: new Date().toString(),
        unixTime: Date.now()
      });

      scope.setContext("Informacoes do Navegador", {
        userAgent: navigator.userAgent,
        language: navigator.language,
        platform: navigator.userAgentData?.platform || navigator.platform || "unknown"
      });

      scope.setContext("Informacoes do Dispositivo", {
        screenWidth: window.screen.width,
        screenHeight: window.screen.height,
        pixelRatio: window.devicePixelRatio,
        orientation: window.screen.orientation?.type || "unknown"
      });

      try {
        const route = window.location;

        if (route) {
          scope.setContext("Rota Atual", {
            path: route.pathname,
            href: route.href,
            search: route.search,
            hash: route.hash
          });
        }
      } catch (e) {
        // NOTE: Silenciar erro se a rota não estiver disponível
      }

      if (userInfo) {
        scope.setUser({
          id: userInfo.id,
          email: userInfo.email,
          username: userInfo.name
        });

        scope.setContext("Contexto do Usuario", {
          teamId: userInfo.teamId,
          projectId: userInfo.projectId,
          role: userInfo.role
        });
      }

      if (params) {
        scope.setContext("Parametros Personalizados", params);
      }

      if (typeof error === "object" && error !== null) {
        if ("request" in error || "response" in error) {
          const networkError = error as {
            request?: {
              readyState?: number;
              status?: number;
              responseURL?: string;
              getAllResponseHeaders?: () => Record<string, string>;
            };
            response?: { status?: number; statusText?: string; data?: Record<string, unknown> };
            data?: Record<string, unknown>;
          };

          scope.setTag("Tipo de Erro", "Rede");
          scope.setTag("Status da Conexao", navigator.onLine ? "online" : "offline");

          const queryParams = this.parseQueryParams(networkError.request?.responseURL);

          if (queryParams) {
            const queryParamsStr = JSON.stringify(queryParams, null, 2);

            scope.setContext("Parametros de Query", {
              url: networkError.request?.responseURL,
              params_json: queryParamsStr
            });
          }

          scope.setContext("Detalhes da Conexao", {
            type: navigator.connection?.type,
            effectiveType: navigator.connection?.effectiveType,
            downlink: navigator.connection?.downlink,
            rtt: navigator.connection?.rtt
          });

          const requestDetails = {
            readyState: networkError.request?.readyState,
            status: networkError.request?.status,
            responseURL: networkError.request?.responseURL,
            responseHeaders: networkError.request?.getAllResponseHeaders?.() || null
          };

          if (requestBody || networkError.data) {
            const sanitizedBody = this.sanitizePayload(requestBody || networkError.data || {});
            const bodyStr = JSON.stringify(sanitizedBody, null, 2);

            scope.setContext("Detalhes da Requisicao", {
              ...requestDetails,
              body_json: bodyStr
            });
          } else {
            scope.setContext("Detalhes da Requisicao", requestDetails);
          }

          const responseData = this.sanitizePayload(networkError.response?.data || {});
          const responseDataStr = JSON.stringify(responseData, null, 2);

          scope.setContext("Detalhes da Resposta", {
            status: networkError.response?.status,
            statusText: networkError.response?.statusText,
            data_json: responseDataStr
          });
        }

        if ("url" in error || "method" in error || "headers" in error) {
          const requestData = error as {
            url?: string;
            method?: string;
            headers?: Record<string, string>;
            params?: Record<string, string>;
            data?: Record<string, unknown>;
          };

          const queryParams = this.parseQueryParams(requestData.url);

          if (queryParams) {
            const queryParamsStr = JSON.stringify(queryParams, null, 2);

            scope.setContext("Parametros de Query", {
              url: requestData.url,
              params_json: queryParamsStr
            });
          }

          const requestDetails = {
            url: requestData.url,
            method: requestData.method,
            headers: this.sanitizeHeaders(requestData.headers || {}),
            params: requestData.params
          };

          if (requestBody || requestData.data) {
            const sanitizedBody = this.sanitizePayload(requestBody || requestData.data || {});
            const bodyStr = JSON.stringify(sanitizedBody, null, 2);

            scope.setContext("Detalhes da Requisicao", {
              ...requestDetails,
              body_json: bodyStr
            });
          } else {
            scope.setContext("Detalhes da Requisicao", requestDetails);
          }
        }
      } else {
        scope.setTag("Tipo de Erro", "Rede");
        scope.setTag("Status da Conexao", navigator.onLine ? "online" : "offline");

        scope.setContext("Detalhes da Conexao", {
          type: navigator.connection?.type,
          effectiveType: navigator.connection?.effectiveType,
          downlink: navigator.connection?.downlink,
          rtt: navigator.connection?.rtt
        });

        const requestDetails = {
          status: 0,
          responseURL: "N/A"
        };

        if (requestBody) {
          const sanitizedBody = this.sanitizePayload(requestBody);
          const bodyStr = JSON.stringify(sanitizedBody, null, 2);

          scope.setContext("Detalhes da Requisicao", {
            ...requestDetails,
            body_json: bodyStr
          });
        } else {
          scope.setContext("Detalhes da Requisicao", requestDetails);
        }

        const errorData = this.sanitizePayload({ mensagem: errorMessage?.message || "Sem detalhes" });
        const errorDataStr = JSON.stringify(errorData, null, 2);

        scope.setContext("Detalhes da Resposta", {
          status: 500,
          statusText: errorMessage?.title || "Erro Desconhecido",
          data_json: errorDataStr
        });
      }

      let customErrorName = "";

      if (
        typeof error === "object" &&
        error !== null &&
        "response" in error &&
        error.response &&
        typeof error.response === "object" &&
        "status" in error.response
      ) {
        customErrorName += `[Status ${error.response.status}] `;
      } else if (errorMessage?.title) {
        customErrorName += `[${errorMessage.title}] `;
      }

      if (errorCode) {
        customErrorName += `[Código: ${errorCode}] `;
      }

      if (params && params.endpoint) {
        customErrorName += `[Endpoint: ${params.endpoint}] `;
      }

      if (errorMessage?.message) {
        customErrorName += errorMessage.message;
      } else if (error instanceof Error) {
        customErrorName += error.message;
      } else {
        customErrorName += "Erro desconhecido";
      }

      const errorObj = error instanceof Error ? new Error(customErrorName) : new Error(customErrorName);

      if (error instanceof Error && error.stack) {
        errorObj.stack = error.stack;
      }

      if (error instanceof Error) {
        (errorObj as any).cause = error;
      }

      captureException(errorObj);
    });
  }

  /**
   * Captura manualmente um replay da sessão atual.
   * Útil para capturar replays em situações específicas, mesmo sem erros.
   * @param reason Motivo pelo qual o replay está sendo capturado
   * @param metadata Metadados adicionais para o replay
   */
  captureReplay(reason: string, metadata?: Record<string, any>): void {
    withScope(scope => {
      scope.setTag("Razao do Replay", reason);
      scope.setTag("Tem Replay", "true");
      scope.setTag("Replay ID", `replay_${Date.now()}_${Math.random().toString(36).substring(2, 9)}`);

      if (metadata) {
        scope.setContext("Metadados do Replay", metadata);
      }

      captureMessage(`Replay Manual: ${reason}`);
    });
  }
}

export const sentryService = new SentryService();
