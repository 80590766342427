// Core
import { NavigationGuard, RouteLocationRaw } from "vue-router";

// Stores
import pinia from "@/store/pinia";

// Types
import { UserParamsT } from "@/@types/user";

// Stores
import { useUserStore } from "@/store/user";
import { useTeamStore } from "@/store/team";

const requireInvite: NavigationGuard = async (to, _from, next) => {
  try {
    const userStore = useUserStore(pinia);

    const infos = userStore.userInfos;

    const { inviteId, email, token } = to.query as UserParamsT;

    const logged = inviteId ? inviteId && email === infos?.email : infos?.id;

    if (!logged) return next();

    const onboarded = infos?.onboarding.hasCompleted || infos?.onboarding.hasCompletedStep2;

    if (onboarded) {
      const dashboard: RouteLocationRaw = { name: "dashboard" };

      if (token) dashboard.query = { token };

      next(dashboard);
    }

    const teamStore = useTeamStore(pinia);

    const pendingInvite = teamStore.pendingInvites.length;

    if (pendingInvite) return next();

    const step = infos?.onboarding.currentStep;

    if (step === 6) return next("subscription");

    if (step === 5) return next("meetbase-onboarding");

    next();
  } catch (error) {
    next({ name: "500" });
  }
};

export default requireInvite;
