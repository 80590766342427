// Core
import { NavigationGuard } from "vue-router";

// Stores
import pinia from "@/store/pinia";

// Stores
import { useTeamStore } from "@/store/team";

const requireValidSubscription: NavigationGuard = (_to, _from, next) => {
  try {
    const teamStore = useTeamStore(pinia);

    if (teamStore.isPlanInvalid || teamStore.isSubscriptionLate) {
      next({ name: "Plans" });
    } else {
      next();
    }
  } catch (error) {
    next({ name: "500" });
  }
};

export default requireValidSubscription;
