// Core
import { createApp, App as VueApp } from "vue";
import router from "./router";
import App from "./App.vue";

// Libraries
import "bootstrap";
// @ts-ignore
import drag from "v-drag";
import * as Sentry from "@sentry/vue";
import ElementPlus from "element-plus";
import PrimeVue from "primevue/config";
import Aura from "@primeuix/themes/aura";
import { createHead } from "@vueuse/head";
import VueDragscroll from "vue-dragscroll";
import { MotionPlugin } from "@vueuse/motion";
import VueDatePicker from "@vuepic/vue-datepicker";
import { autoAnimatePlugin } from "@formkit/auto-animate/vue";
import { plugin as VueInputAutowidth } from "vue-input-autowidth";
import { onAuthStateChanged, getAuth, signOut } from "firebase/auth";
import { apiMultipartService, apiService } from "./core/services/apiService";

// Services & Helpers
import { initToast } from "@/plugins/toast";
import { vFocus } from "./directives/focus";
import posthogPlugin from "@/plugins/posthog";
import { projectAuth } from "./firebase/config";
import { storageSession } from "@/utils/storage";
import { initInlineSvg } from "@/plugins/inline-svg";
import { PtBr } from "./helpers/element-plus/lang-ptbr";
import { initializeAnalytics } from "./core/services/analyticsService";

// Composables
import { useUser } from "./composables/useUser";
import { useProjectStore } from "./store/project";

// Stores
import pinia from "@/store/pinia";
import { useTeamStore } from "@/store/team";

// Assets
import "nprogress/nprogress.css";
import "@vuepic/vue-datepicker/dist/main.css";
import { UserDetailsAdmin } from "./@types/user";
import { errorHandlerService } from "./core/services/error";

declare global {
  interface Window {
    dataLayer: Record<string, unknown>[];
    fbq: (type: string, event: string) => void;
    clarity: ((set: string, key: string, value: string) => void) & ((event: string, value: string) => void);
  }
}

let app: VueApp | undefined;
let head;

const initializeApp = () => {
  app = createApp(App);
  head = createHead();

  app.config.errorHandler = (error, _vm, info) => {
    console.error("Vue Error:", error, info);
  };

  app.use(pinia);
  app.use(head);
  app.use(router);
  app.use(drag, {});
  app.use(PrimeVue, {
    theme: {
      preset: Aura
    }
  });
  app.use(MotionPlugin);
  app.use(VueDragscroll);
  app.use(posthogPlugin);
  app.use(VueInputAutowidth);
  app.use(autoAnimatePlugin);
  app.use(ElementPlus, { locale: PtBr });

  app.component("VueDatePicker", VueDatePicker);

  app.directive("focus", vFocus);

  initInlineSvg(app);
  initToast(app);

  initializeAnalytics();

  Sentry.init({
    app,
    tracesSampleRate: 0.0,
    replaysOnErrorSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    environment: import.meta.env.MODE || "production",
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
        maskAllInputs: false
      })
    ],
    dsn: "https://975f6ec67bd66350d0d5035edfd27968@o4504255628967936.ingest.us.sentry.io/4506659847995392",
    beforeSend(event) {
      const tags = event.tags || {};

      if (tags.error_source === "api") {
        return event;
      }

      return null;
    },
    attachStacktrace: true,
    defaultIntegrations: false
  });

  app.mount("#app");
};

onAuthStateChanged(projectAuth, async user => {
  if (!app) initializeApp();

  const { setUserStatus } = useUser();

  if (!user) {
    storageSession.clear();

    try {
      await signOut(getAuth());
      setUserStatus();
    } catch (err) {
      errorHandlerService.handleError(err);
    }
    return;
  }

  const token = await user.getIdToken();

  apiService.intercept(token);
  apiMultipartService.intercept(token);

  const { data } = await apiService.get<UserDetailsAdmin>({ endpoint: `users/${user.uid}` });

  if (data) await setUserStatus(data);

  const teamStore = useTeamStore();

  if (!window.location.pathname.includes("f1a3b5c738c9d2f62bac4f37e16d58e9")) {
    await teamStore.fetchTeams();
    await teamStore.migrateTeamProjects();

    if (teamStore.isSubscriptionLate) {
      const subscription = teamStore.subscriptions.find(subscription => subscription.status === "overdue");

      if (subscription) {
        const res = await apiService.get<{ url: string }>({
          endpoint: `subscriptions/${subscription.id}/payment-link`
        });

        if (res.error || !res.data?.url) return;

        teamStore.setPaymentLink(res.data.url);
      }
    }

    const projectStore = useProjectStore();

    await projectStore.fetch({ simplified: true, page: 1 });
  }
});
