// Libraries
import markdownit from "markdown-it";

// Services & Helpers
import { validateUrl } from "./validation";

// Assets
import urlIcon from "@/assets/icons/leads/socials/url.svg?url";
import emailIcon from "@/assets/icons/leads/socials/email.svg?url";
import facebookIcon from "@/assets/icons/leads/socials/facebook.svg?url";
import linkedinIcon from "@/assets/icons/leads/socials/linkedin.svg?url";
import whatsappIcon from "@/assets/icons/leads/socials/whatsapp.svg?url";
import instagramIcon from "@/assets/icons/leads/socials/instagram.svg?url";

export const stringToJson = <T>(text: string): T | undefined => {
  const jsonString = text.replace(/^```json\s*([\s\S]*?)\s*```$/m, "$1");

  let parsedJson: T | undefined;

  try {
    parsedJson = JSON.parse(jsonString);
  } catch (error) {
    console.error("Erro ao parsear o JSON:", error);
  }

  if (parsedJson) {
    console.log("JSON parseado com sucesso:", parsedJson);
  } else {
    console.log("Não foi possível parsear o JSON.");
  }

  return parsedJson;
};

export const separateNumberByComma = (value: string): { integer: string; decimal: string } => {
  const [integer, decimal] = value.split(",");

  return {
    integer: integer || "0",
    decimal: decimal || "00"
  };
};

const replaceWithHTMLTags = (match: string) => {
  return "<p><b>" + match + "</b>";
};

export const capitalizeFirstLetter = (text: string): string => {
  return text.charAt(0).toUpperCase() + text.slice(1);
};

export const formatToHTML = (text: string): string => {
  const md = markdownit({ html: true, xhtmlOut: true, breaks: true, typographer: true });

  text = text.replace(/```markdown([\s\S]*?)```/g, "$1");
  text = text.replace(/&lt;/g, "<").replace(/&gt;/g, ">");

  const formattedText = text.replace(
    /<(?!\/?(p|br|a|b|em|ul|li|ol|h1|h2|h3|h4|h5|h6|hr|s|u|strong|span|img|table|thead|tbody|tr|th|td)\b)[^>]*>/gi,
    " "
  );

  let result = md.render(formattedText);

  result = result.replace(/<table/g, '<div class="table-wrapper"><table');
  result = result.replace(/<\/table>/g, "</table></div>");

  return result;
};

export const formatFileSize = (sizeInBytes: number): string => {
  const sizeInMB = Math.max(0.1, sizeInBytes / 1024 / 1024);

  return (
    sizeInMB.toLocaleString("pt-BR", {
      minimumFractionDigits: 1,
      maximumFractionDigits: 1
    }) + "MB"
  );
};

export const formatComplement = (complement: string | undefined, modelType: string) => {
  if (!complement) return;
  switch (modelType) {
    case "gads":
      return complement.replaceAll("\n", "").replace("Título 2:", " -").replace("Título 1:", "").trim();
    case "seo":
      return complement.replaceAll("\n", "").replace("Título:", "").trim();
    default:
      return complement.replace("Descrição:", "").replaceAll("\n", "");
  }
};

export const formatResponse = (response: string, modelType: string) => {
  let responseFormatted = "";

  switch (modelType) {
    case "fbads":
      const mainText = response.split("Texto Principal:").at(1);
      responseFormatted = (mainText || response)
        .replace("Texto Principal:", "")
        .replace("Texto principal:", "")
        .replace("Main Text:", "")
        .replace("Text Main:", "")
        .trim();
      break;
    case "seo":
      responseFormatted = response.replace("Descrição:", "").trim();
      break;

    case "cron-social":
      responseFormatted = String(response)
        .replace("Segunda-feira:", "<b>Segunda-feira:</b>")
        .replace("Terça-feira:", "<b>Terça-feira:</b>")
        .replace("Quarta-feira:", "<b>Quarta-feira:</b>")
        .replace("Quinta-feira:", "<b>Quinta-feira:</b>")
        .replace("Sexta-feira:", "<b>Sexta-feira:</b>")
        .replaceAll("||", "");
      break;

    case "blog-ideas":
    case "beneficios-caracteristicas":
    case "video-script":
    case "bullets":
    case "tiktok":
      const regex1 = /\(não enumerados\)/gi;
      const regex2 = /\(separados por -\)/gi;
      responseFormatted = String(response)
        .replaceAll(/(\d+)\)/g, "$1.")
        .replace(/^(?:\d+\.\s*|\d+\n)/gm, replaceWithHTMLTags)
        .replaceAll(/((ideia?s?|idea) de v[íi]deo \d+:)/gi, "<strong>$1</strong>")
        .replaceAll(regex1, "")
        .replaceAll(regex2, "")
        .concat("</p>");
      break;

    case "writer-blog-topics":
    case "blog-topics":
      responseFormatted = String("•" + response)
        .replace("2.", "<br />• ")
        .replace("3.", "<br />• ")
        .replace("4.", "<br />• ")
        .replace("5.", "<br />• ")
        .replace("6.", "<br />• ")
        .replace("7.", "<br />• ")
        .replace("8.", "<br />• ")
        .replace("9.", "<br />• ")
        .replace("10.", "<br />• ")
        .replace("2 .", "<br />• ")
        .replace("3 .", "<br />• ")
        .replace("4 .", "<br />• ")
        .replace("5 .", "<br />• ")
        .replace("6 .", "<br />• ")
        .replace("7 .", "<br />• ")
        .replace("8 .", "<br />• ")
        .replace("9 .", "<br />• ")
        .replace("10 .", "<br />• ")
        .replace(";", "");
      break;
    case "writer-conclusion":
    case "blog-conclusao":
      responseFormatted = String(response).replace("1.", "").replace("2.", "").replace("3.", "");
      break;

    case "video-creative":
      responseFormatted = String(response)
        .replace("Descrição:", "<b>Descrição:</b>")
        .replace("Cena 1:", "<b>Cena 1:</b>")
        .replace("Cena 2:", "<b>Cena 2:</b>")
        .replace("Cena 3:", "<b>Cena 3:</b>")
        .replace("Cena 4:", "<b>Cena 4:</b>")
        .replace("Cena 5:", "<b>Cena 5:</b>")
        .replace("Cena 6:", "<b>Cena 6:</b>")
        .replace("CTA:", "<b>CTA:</b>")
        .replace("Observação:", "<b>Observação:</b>")
        .replaceAll("Texto:", "<b>Texto:</b>");
      break;
    case "copy-creative":
      responseFormatted = String(response)
        .replace("Texto:", "<b>Texto:</b>")
        .replace("Design:", "<b>Design:</b>")
        .replace("Preço:", "<b>Preço:</b>");
      break;
    case "post-instagram":
      responseFormatted = String(response)
        .replaceAll("Texto:", "<b>Texto:</b>")
        .replaceAll("Design:", "<b>Design:</b>");
      break;
    default:
      responseFormatted = String(response).replace(/^(?:\d+\.\s*|\d+\n)/gm, replaceWithHTMLTags);
      break;
  }
  responseFormatted.replaceAll("||", "").replaceAll("\\n", "<br/>");

  return responseFormatted;
};

export const formatToHtml = (text: string) => {
  text = removeNewLineAtBeggining(text);
  const html = text
    .replace("1.", "<p><b>1.</b>")
    .replace("2.", "\n<p><b>2.</b>")
    .replace("3.", "\n<p><b>3.</b>")
    .replace("4.", "\n<p><b>4.</b>")
    .replace("5.", "\n<p><b>5.</b>")
    .replace("6.", "\n<p><b>6.</b>")
    .replace("7.", "\n<p><b>7.</b>")
    .replace("8.", "\n<p><b>8.</b>")
    .replace("9.", "\n<p><b>9.</b>")
    .replace("10.", "\n<p><b>10.</b>")
    .replace("1 .", "<p><b>1.</b>")
    .replace("2 .", "\n<p><b>2.</b>")
    .replace("3 .", "\n<p><b>3.</b>")
    .replace("4 .", "\n<p><b>4.</b>")
    .replace("5 .", "\n<p><b>5.</b>")
    .replace("6 .", "\n<p><b>6.</b>")
    .replace("7 .", "\n<p><b>7.</b>")
    .replace("8 .", "\n<p><b>8.</b>")
    .replace("9 .", "\n<p><b>9.</b>")
    .replace("10 .", "\n<p><b>10.</b>")

    .concat("</p>");

  return html;
};

export const removeNewLineAtBeggining = (text: string) => {
  const regex = /^\n/;
  let newText = text.replace(regex, "");
  while (regex.test(newText)) {
    newText = newText.replace(regex, "");
  }
  return newText;
};

export const createPhoneMask = (phone: string) => {
  let mask = "";

  if (phone.length > 0) {
    mask = "(";
  }

  if (phone.length > 2) {
    mask = mask.concat(phone.slice(0, 2), ") ");
  } else {
    mask = mask.concat(phone.slice(0, 2));
  }

  if (phone.length > 6) {
    mask = mask.concat(phone.slice(2, 7));
  } else {
    mask = mask.concat(phone.slice(2, 7));
  }
  if (phone.length > 7) {
    mask = mask.concat("-", phone.slice(7, 11));
  } else {
    mask = mask.concat(phone.slice(7, 11));
  }

  return mask;
};

export const removePhoneMask = (phone: string) => {
  const regex = /[\(\)\-\s]/g;
  return phone.replace(regex, "");
};

export const formatQueryParams = (params: Record<string, string | string[] | number | boolean | Date>) => {
  const esc = encodeURIComponent;
  return Object.keys(params)
    .map(k => (params[k] ? esc(k) + "=" + esc(params[k] as string) : ""))
    .filter((item: string) => item !== "")
    .join("&");
};

// Função utilizada para realizar buscas de texto - comparação de filtros
export const textComapare = (text1: string, text2: string) => {
  return text1
    .toLowerCase()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]+|[^a-zA-Z0-9 ]/g, "")
    .replace(" ", "")
    .includes(
      text2
        .toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]+|[^a-zA-Z0-9 ]/g, "")
        .replace(" ", "")
    );
};

export const numberToFromatedInteger = (number: number) => {
  return Math.trunc(number).toLocaleString("pt-BR", { minimumFractionDigits: 0, maximumFractionDigits: 0 });
};

export const formatParagraphsToHtml = (text: string) => {
  const paragraphs = text.split("\n");
  let html = "";
  paragraphs.forEach(paragraph => {
    if (!paragraph.trim()) return (html = html.concat("<p>", paragraph, "</p>"));
    return;
  });
  return html;
};

export const formatSourcesIntoLinks = (sources: { title: string; link: string }[]) => {
  let formattedSources = "";
  sources.forEach((source, index) => {
    if (!source.link || !validateUrl(source.link)) return;
    formattedSources += `<span style="margin: 0px">${index + 1}</span> - <a href="${source.link}">${
      source.title
    }</a>; `;
  });
  return `<p><strong>Saiba mais:</strong> ${formattedSources}</p>`;
};

export const formatShortUrl = (link: string) => {
  return link.replace(/https?:\/\//, "").split("/")[0];
};

export const formatRole = (role: string) => {
  switch (role) {
    case "basic":
      return "Básico";
    case "profissional":
      return "Pro";
    case "scale":
      return "Escala";
    case "business1":
      return "Business 1";
    case "business2":
      return "Business 2";
    case "business3":
      return "Business 3";
    case "business4":
      return "Business 4";
    case "business5":
      return "Business 5";
    case "admin":
      return "Admin";
    case "lead":
      return "Sem plano";
    default:
      return role;
  }
};

export function splitIntoChunks(str: string, chunkSize = 12) {
  // Use regex to split the string at every sequence of 12 words
  const sentences = str.split("\n"); // Split the string by whitespace
  const result: string[] = [];
  for (const set of sentences) {
    const words = set.split(" ");
    const chunks: string[] = [];
    for (let i = 0; i < words.length; i += chunkSize) {
      chunks.push(words.slice(i, i + chunkSize).join(" "));
    }
    result.push(chunks.join("\n"));
  }

  return result;
}

export const convertChunksIntoParagraphs = (chunks: string[]) =>
  "<p>" + chunks.join("\n").trim().replaceAll("\n", "</p><p>").replaceAll("<p></p>", "") + "</p>";

export const formatPhoneNumber = (phoneNumber?: string | null): string | undefined => {
  if (!phoneNumber) return;

  const countryCode = phoneNumber.slice(0, 3);
  const areaCode = phoneNumber.slice(3, 5);
  const prefix = phoneNumber.slice(5, 10);
  const suffix = phoneNumber.slice(10);

  if (suffix.length === 4) {
    return `${countryCode} (${areaCode}) ${prefix}-${suffix}`;
  } else {
    const newPrefix = prefix.slice(0, -1);
    const newSuffix = prefix.slice(-1) + suffix;
    return `${countryCode} (${areaCode}) ${newPrefix}-${newSuffix}`;
  }
};

export const convertToQuery = (queryRaw: Record<string, string | number>) => {
  let query = "";

  Object.entries(queryRaw).forEach(([key, value]) => {
    if (value) {
      query += `${key}=${value}&`;
    }
  });

  if (query) {
    query = "?" + query.slice(0, -1);
  }

  return query;
};

const socialNetworks = [
  {
    name: "WhatsApp",
    regex: /((^|\/\/)(wa\.me|api\.whatsapp|whatsapp\.com))/,
    userPath: /(?:wa\.me|whatsapp\.com)\/(\d+)/,
    icon: whatsappIcon
  },
  { name: "Instagram", regex: /instagram\.com/, userPath: /instagram\.com\/([^\/]+)/, icon: instagramIcon },
  {
    name: "Facebook",
    regex: /facebook\.com(\.br)?/,
    userPath: /facebook\.com(?:\.br)?\/(?:[a-zA-Z0-9-]+\/)?([^\/]+)/,
    icon: facebookIcon
  },
  { name: "Twitter", regex: /twitter\.com/, userPath: /twitter\.com\/([^\/]+)/, icon: urlIcon },
  { name: "LinkedIn", regex: /linkedin\.com/, userPath: /linkedin\.com\/(?:in|company)\/([^\/]+)/, icon: linkedinIcon },
  {
    name: "YouTube",
    regex: /youtube\.com|youtu\.be/,
    userPath: /(?:youtube\.com\/channel|youtu\.be)\/([^\/]+)/,
    icon: urlIcon
  },
  { name: "TikTok", regex: /tiktok\.com/, userPath: /tiktok\.com\/@([^\/]+)/, icon: urlIcon },
  { name: "Pinterest", regex: /pinterest\.com/, userPath: /pinterest\.com\/([^\/]+)/, icon: urlIcon },
  { name: "Snapchat", regex: /snapchat\.com/, userPath: /snapchat\.com\/add\/([^\/]+)/, icon: urlIcon },
  { name: "Telegram", regex: /t\.me/, userPath: /t\.me\/([^\/]+)/, icon: urlIcon },
  { name: "E-mail", regex: /^[^\s@]+@[^\s@]+\.[^\s@]+$/, userPath: /^([^\s@]+@[^\s@]+\.[^\s@]+)/, icon: emailIcon }
];

export const formatLink = (url: string) => {
  const https = "https://";

  if (url.startsWith(https)) {
    url = url.slice(https.length);
  }

  const http = "http://";

  if (url.startsWith(http)) {
    url = url.slice(http.length);
  }

  if (url.endsWith("/")) {
    url = url.slice(0, -1);
  }

  if (!(url.includes("whatsapp") || url.includes("wa"))) {
    return url.split("?")[0];
  }
  return url;
};

export const formatUrl = (url: string, removePath: boolean = false) => {
  let urlWithoutProtocol = url.replace(/^https?:\/\//, "");
  urlWithoutProtocol = urlWithoutProtocol.replace(/^www\./i, "");

  if (removePath) {
    const firstSlashIndex = urlWithoutProtocol.indexOf("/");

    if (firstSlashIndex !== -1) {
      urlWithoutProtocol = urlWithoutProtocol.slice(0, firstSlashIndex);
    }
  }

  const urlFormatted = urlWithoutProtocol.endsWith("/") ? urlWithoutProtocol.slice(0, -1) : urlWithoutProtocol;

  return urlFormatted;
};

export const formatSocialNetwork = (url: string) => {
  const urlFormatted = formatUrl(url);

  for (const socialNetwork of socialNetworks) {
    if (socialNetwork.regex.test(url)) {
      const match = urlFormatted.match(socialNetwork.userPath);
      const user = match ? (match[1] ?? "") : "";

      return { url: socialNetwork.name, icon: socialNetwork.icon, label: urlFormatted, user };
    }
  }

  return { url, icon: urlIcon, label: urlFormatted, user: "" };
};

export const extractWhatsappNumber = (url: string) => {
  url = url.replace("-", "");

  const phoneRegex1 = /wa\.me\/(\+?55)?(\d{2})(\d{4,5})(\d{4})/;
  const phoneRegex2 = /api\.whatsapp\.com\/send\?phone=(\+?55)?(\d{2})(\d{4,5})(\d{4})/;

  const match = url.match(phoneRegex1) || url.match(phoneRegex2);

  if (!match) {
    return url;
  }

  const [, , areaCode, part1, part2] = match;

  return `+55 ${areaCode} ${part1}-${part2}`;
};

export const formatCreatedAt = (date: Date) => {
  const now = new Date(new Date().getTime());
  const diff = now.getTime() - date.getTime();
  const minutes = Math.floor(diff / (1000 * 60));
  const hours = Math.floor(diff / (1000 * 60 * 60));
  const days = Math.floor(diff / (1000 * 60 * 60 * 24));
  const months = Math.floor(days / 30);
  const years = Math.floor(months / 12);

  if (minutes < 1) {
    return "Gerado agora";
  }

  if (minutes === 1) {
    return "Gerado há 1 minuto";
  }

  if (minutes < 60) {
    return `Gerado há ${minutes} minutos`;
  }

  if (hours === 1) {
    return "Gerado há 1 hora";
  }

  if (hours < 12) {
    return `Gerado há ${hours} horas`;
  }

  if (days === 1) {
    return "Gerado há 1 dia";
  }

  if (days <= 30) {
    return `Gerado há ${days} dias`;
  }

  if (months === 1) {
    return "Gerado há 1 mês";
  }

  if (months < 12) {
    return `Gerado há ${months} meses`;
  }

  if (years === 1) {
    return "Gerado há 1 ano";
  }

  return `Gerado há ${years} anos`;
};

export const formatSelectValue = (value: string) => ({ label: value, value });

export const capitalize = (word: string) => word.charAt(0).toUpperCase() + word.slice(1);

export const rgbStyleToHex = (color: string | null): string | null => {
  if (!color || color.indexOf("rgb") < 0) return color;

  if (color.charAt(0) == "#") return color;

  const nums = /(.*?)rgb\((\d+),\s*(\d+),\s*(\d+)\)/i.exec(color);
  const r = parseInt(nums?.[2]!, 10).toString(16);
  const g = parseInt(nums?.[3]!, 10).toString(16);
  const b = parseInt(nums?.[4]!, 10).toString(16);

  return (
    "#" +
    ((r.length == 1 ? "0" + r : r) + (g.length == 1 ? "0" + g : g) + (b.length == 1 ? "0" + b : b))
  ).toUpperCase();
};
