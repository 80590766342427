// Libraries
import * as yup from "yup";

// Services & Helpers
import { apiService } from "@/core/services/apiService";
import { errorHandlerService } from "@/core/services/error";

// Types
import type { FormError, FormUserData } from "../@types/chat";
import { ValidationRuleT, ValidatorT } from "@/@types/validation";

export const validate = (user: FormUserData): [boolean, FormError[]] => {
  const formError: FormError[] = [];

  const nameValidation = validateName(user.name);
  if (nameValidation) {
    formError.push(nameValidation);
  }

  const phoneValidation = validatePhone(user.phone);
  if (phoneValidation) {
    formError.push(phoneValidation);
  }

  const emailValidation = validateEmail(user.email);
  if (emailValidation) {
    formError.push(emailValidation);
  }

  if (formError.length) {
    return [false, formError];
  }
  return [true, formError];
};

export const validateEmail = (email: string): FormError | null => {
  const emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
  if (!email) {
    return {
      field: "email",
      message: "Por favor, digite seu email"
    };
  } else if (!email.match(emailRegex)) {
    return {
      field: "email",
      message: "Por favor, digite um email válido"
    };
  }
  return null;
};

export const validatePhone = (phone: string): FormError | null => {
  const phoneRegex = /^\([0-9]{2}\)\s[0-9]{5}\-[0-9]{4}$/;
  if (!phone) {
    return {
      field: "phone",
      message: "Por favor, digite seu telefone"
    };
  } else if (!phone.match(phoneRegex)) {
    return {
      field: "phone",
      message: "O telefone precisa ter 11 dígitos"
    };
  }
  return null;
};

export const validateName = (name: string): FormError | null => {
  if (!name) {
    return {
      field: "name",
      message: "Por favor, digite seu nome"
    };
  } else if (name.split(" ").length < 2) {
    return {
      field: "name",
      message: "É necessário nome e sobrenome"
    };
  }
  return null;
};

export const updateErrorArray = (errors: FormError[], field: "email" | "phone" | "name", message: string | null) => {
  if (message) {
    const index = errors.findIndex(error => error.field === field);
    if (index !== -1) {
      errors = errors.map(error => {
        if (error.field === field) {
          return { field, message };
        }
        return error;
      });
    } else {
      errors.push({ field, message });
    }
  } else {
    errors = errors.filter(error => error.field !== field);
    return errors;
  }
  return errors;
};

export const inviteQueryValidate = (query: Record<string, string>) => {
  const querySchema = yup.object().shape({
    email: yup.string().email().required(),
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    teamName: yup.string().required(),
    inviteId: yup.string().required(),
    teamId: yup.string().required(),
    userId: yup.string()
  });

  try {
    return { ...querySchema.validateSync(query), error: null };
  } catch (err) {
    return { error: "Ocorreu um erro de validação" };
  }
};

export const validateUrl = (url: string) => {
  const pattern = new RegExp(
    "^(https?:\\/\\/)?" +
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" +
      "((\\d{1,3}\\.){3}\\d{1,3}))" +
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" +
      "(\\?[;&a-z\\d%_.~+=-]*)?" +
      "(\\#[-a-z\\d_]*)?$",
    "i"
  );

  return pattern.test(url);
};

export const isSocialMediaLink = (url: string): boolean => {
  if (!validateUrl(url)) return false;

  const patterns = {
    youtube:
      /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/,
    instagram: /^((?:https?:)?\/\/)?((?:www\.)?)((?:instagram\.com|instagr\.am))\/([A-Za-z0-9-_\.]+)/,
    tiktok: /^((?:https?:)?\/\/)?((?:www|vm)\.)?tiktok\.com\/@?([A-Za-z0-9-_\.]+)/
  };

  return patterns.youtube.test(url) || patterns.instagram.test(url) || patterns.tiktok.test(url);
};

export const isSequentialNumber = (value: string) => {
  const positions = value.split("");

  const sequentialIncrement: boolean[] = [];
  const sequentialDecrement: boolean[] = [];

  positions.forEach((value, index, self) => {
    const last = index === self.length - 1;

    if (last) return;

    const next = parseInt(self[index + 1] as string);

    const diff = next - parseInt(value);
    isSequentialNumber;

    const increment = diff === 1;
    const decrement = diff === -1;

    sequentialIncrement.push(increment);
    sequentialDecrement.push(decrement);
  });

  const isIncrement = sequentialIncrement.every(value => value === true);
  const isDecrement = sequentialDecrement.every(value => value === true);

  return isIncrement || isDecrement;
};

export const phoneValidation = (phone: string, format: string | string[]) => {
  let patterns: string[];

  if (typeof format === "string" && format.startsWith("[") && format.endsWith("]")) {
    try {
      patterns = JSON.parse(format);
    } catch (error) {
      console.error("Erro ao analisar o formato JSON:", error);
      patterns = [format];
    }
  } else if (Array.isArray(format)) {
    patterns = format;
  } else {
    patterns = [format as string];
  }

  const digits = phone.replace(/\D/g, "");

  const isSequence = isSequentialNumber(digits.slice(2));
  const repeatInEveryPosition = /^(.)\1*$/.test(digits.slice(2));

  if (repeatInEveryPosition) return false;
  if (isSequence) return false;

  const escapeRegex = (str: string) => str.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&");

  const regexes = patterns.map(pattern => new RegExp("^" + escapeRegex(pattern).replace(/#/g, "\\d") + "$"));

  return regexes.some(regex => regex.test(phone));
};

export const validateEmailCall = async (email: string): Promise<true | { error: string }> => {
  try {
    const isEmailValid = await apiService.post({
      endpoint: "black-listed-domains/validate",
      body: {
        email: email
      }
    });

    const emailErrored = isEmailValid.error;

    if (emailErrored)
      return {
        error: emailErrored
      };
    return true;
  } catch (err) {
    errorHandlerService.handleError(err);
    return {
      error: (err as { message: string }).message
    };
  }
};

export const createValidator = (validationRules: ValidationRuleT[]): ValidatorT => ({
  value: "",
  error: false,
  errorTxt: "",
  validate() {
    for (const { test, message } of validationRules) {
      if (!test(this.value)) {
        this.error = true;
        this.errorTxt = message;
        return;
      }
    }

    this.error = false;
    this.errorTxt = "";
  }
});

export const passwordValidators = [
  {
    test: (value: string) => value.length >= 8,
    message: "Mínimo de 8 caracteres"
  },
  {
    test: (value: string) => /\d/.test(value),
    message: "Conter pelo menos 1 número"
  },
  {
    test: (value: string) => /[a-zA-Z]/.test(value),
    message: "Conter pelo menos 1 letra"
  }
];
